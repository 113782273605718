import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaUser } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { RiContactsBook3Fill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";

const Footer = () => {
    return (
        <footer className="text-center py-3 mt-auto" style={{ backgroundColor: '#e9ecef' }}>
            <div className="container">
                <div className="row">
                    {/* First Column - Title */}
                    <div className="col-md-4 d-flex  align-items-center">
                        <p className='fw-bold h2'>Contact Us: </p>
                    </div>

                    {/* Second Column - Name and Phone Numbers */}
                    <div className="col-md-4 align-items-center">
                        <p className='text-start'><FaUser /><span className="fw-bold ms-1"> Mr. Divyang Amin</span></p>
                        <p className='text-start'><BsFillTelephoneFill /><span className="fw-bold ms-1"> +91 94271 09025</span></p>
                        <p className='text-start'><RiContactsBook3Fill className='h4' /><span className="fw-bold ms-2">079 2676 4899</span></p>
                    </div>

                    {/* Third Column - Email and Address */}
                    <div className="col-md-4 align-items-center">
                        <p className='text-start'><MdEmail className='h4' /><span className="fw-bold ms-1"> aminelectricco@gmail.com</span></p>
                        {/* Add address if needed */}
                        <p className='text-start'><FaLocationDot className='h4' /><span className="fw-bold ms-1"> A/116, Popular Plaza, Someshwar Complex-1, Nr. Shyamal Cross Roads, Satellite, Ahmedabad - 380015, Gujrat(India)</span></p>
                    </div>
                </div>
            </div>

            <div className="container mt-3">
                <span className="text-muted">© 2024 Amin Electric Co All rights reserved.</span>
            </div>
        </footer>

    );
}

export default Footer;